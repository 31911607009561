export default class WishListCard {
    constructor(el, productData) {
        this.$el = el;
        this.$addToCart = this.$el.querySelector('[data-add-to-cart]');
        this.$removeItem = this.$el.querySelector('[data-remove]');
        this.productData = productData;
        this.addEventListeners();
        this.checkIfProductIsInStock();
    }

    addEventListeners() {
        this.$addToCart.addEventListener('click', () => {
            this.addToCart();
        });
        this.$removeItem.addEventListener('click', () => {
            this.removeItem();
        });
    }

    checkIfProductIsInStock() {
        const params = {
            epi: this.productData.epi,
            empi: this.productData.empi,
            du: this.productData.du,
        };

        window._swat.getProductDetails(params, productJson => {
            const isInStock = productJson.available;
            if (!isInStock) {
                this.$addToCart.disabled = true;
                this.$addToCart.innerText = 'Out Of Stock';
            }
        });
    }

    addToCart() {
        window._swat.replayAddToCart(
            this.productData,
            this.productData.epi,
            response => {
                const productData = JSON.parse(response);
                window.dispatchEvent(
                    new CustomEvent('cart.added', { detail: { productData } })
                );
            }
        );
    }

    removeItem() {
        const params = {
            epi: this.productData.epi,
            empi: this.productData.empi,
            iu: this.productData.iu,
            pr: this.productData.pr,
        };
        window._swat.removeFromWishList(params, () => {
            this.$el.remove();
            window.dispatchEvent(
                new CustomEvent('wishlistItem.removed', {
                    detail: 'item removed',
                })
            );
        });
    }
}
